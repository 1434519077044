import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import LenderRate from '../components/LenderPages/LenderRate';
import { FaHeadphonesAlt, FaPhoneAlt, FaRegEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import { boxVariant, listVariant } from '../lib/animation';
import LenderRateView from '../components/LenderPages/LenderRateView';
import { getApiRatesDB } from '../lib/api';
import NewBanner from '../components/NewBanner';
import GuideCTA from '../components/GuideCTA';
import CalculatorForm from '../components/CalculatorForm';
import StickySideSection from '../components/LenderPages/StickySideSection';

function LenderPage(props) {
    const data = props;
    // console.log(data);
    const postpath = data.data.slug;
    const [lendersData, setlendersData] = useState(data.data.acf.lender_pages_section);
    const [lenderPages, setlenderPages] = useState([]);
    const [rateType, setRateType] = useState(data.data.acf.rates_show)
    const [ratesBtn, setRatesBtn] = useState(data.data.acf.show_all_ratetypes);

    
    useEffect(() => {
        const loadPage = async () => {
            let api = process.env.REACT_APP_API_URL;
            await axios.all([
                axios.get(api + 'wp-json/wp/v2/lender_pages?_embed&slug=' + postpath),
            ])
                .then(axios.spread((result) => {
                    setlendersData(result.data[0]);
                    // console.log(result)
                    // console.log(JSON.stringify(result))
                    setRatesBtn(result.data[0].acf.show_all_ratetypes)
                    setRateType(result.data[0].acf.rates_show)
                }))
                .catch(error => {
                    // setError(error);
                });
        };
        // loadPage();
    }, [postpath]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setlendersData(data.data.acf.lender_pages_section);
        setRatesBtn(data.data.acf.lender_pages_section.show_all_ratetypes)
        setRateType(data.data.acf.lender_pages_section.rates_show)
    }, [postpath]);

    useEffect(() => {
        const loadAllLenderPages = async () => {
            let api = process.env.REACT_APP_API_URL;
            await axios.all([
                axios.get(api + 'wp-json/wp/v2/lender_pages'),
            ])
                .then(axios.spread((result) => {
                    // console.log(result.data)
                    // console.log("Result loadAllLenderPages " + JSON.stringify(result.data[0]))
                    setlenderPages(result.data);
                }))
                .catch(error => {
                    // setError(error);
                });
        };
        // loadAllLenderPages();
    }, []);
    function changeRateActive(rateType) {
        setRateType(rateType);
    }
    return (
        <div className="deals-page">
            <div className="container deals-selection-section">
                {/* <h1 className="section-header"><strong>
                    <div className="text-center mt-10" dangerouslySetInnerHTML={{ __html: data?.data?.title?.rendered }}></div></strong>
                </h1> */}
                <div className='mt-4'>
                    <NewBanner heading={lendersData?.banner_content} noBannerImage={true} noRatingsSection={true} />
                </div>
                {
                    lendersData?.lender_banner?.sizes?.large &&
                    <div className="new-banner-section max-1200 mx-auto">
                        <img src={lendersData?.lender_banner?.sizes?.large} alt={data?.data?.title?.rendered} className='img-fluid' />
                    </div>
                }
                {
                    (lendersData?.rate_display_preference === 'top3') ? renderTop3Rates(lendersData?.rates_show_calc, lendersData?.lender.value, lendersData) : calcRates(rateType)
                }
                <div className="mt-5">
                <CalculatorForm activeCalculator={lendersData?.calculators} pageId="calculators" />
                </div>
                {
                    lendersData?.fixed_section_content?
                    <StickySideSection stickyContent={lendersData?.fixed_section_content} lendersData={lendersData?.details_sections} lendersID={lendersData?.lender?.value} /> :
                    <div className="container-fluid max-900 mx-auto">
                        {
                            lendersData && lendersData?.details_sections.map((item, i) => {
                                return (
                                    <div key={i}>
                                        <div dangerouslySetInnerHTML={{ __html: item?.content }}></div>
                                        {
                                            item.cta_text &&
                                            <GuideCTA cta_text={item.cta_text} />
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                {
                    false &&
                    <div className='max-900 container pb-4'>
                        <hr></hr>
                        <h2 className="text-center font-medium font-underline">More Lender Pages</h2>
                        <div className="row justify-content-between"></div>
                        <div className='container py-5 font-medium'>
                            <div className="row font-regular">
                                {
                                    renderAllLenderPages(lenderPages)
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );

    function calcRates(rateType) {
        return <>
            <h5 className="text-center text-bold mb-4 mt-5">
                {
                    rateType && rateType.value != null && renderDescription(rateType)
                }
            </h5>
            <div className="container deals-selection-section">
                <div className="row justify-content-center">
                    {
                        rateType && rateType.value != null && renderButtons(ratesBtn, rateType)
                    }
                </div>
            </div>
            {
                rateType && rateType.value != null && renderLenderRate(rateType, lendersData)
            }
            <div className='container1 banner-cta mt-0'>
                {
                    renderLenderDetails(props, lendersData)
                }
            </div>
        </>
    }

    function renderDescription(rateType) {
        // console.log("renderDescription " + rateType)
        switch (rateType.value) {
            case 'first-time-buyer':
                return "See what's the latest rates available for your first mortgage. Our mortgage rates are a great tool to give you an idea about the latest rates available as well as monthly mortgage payments. Get in touch to find out the most suitable mortgage deal for you.";
            case "remortgaging":
                return "See what's the latest rates available for your Remortgage. Our mortgage rates are a great tool to give you an idea about the latest rates available as well as monthly mortgage payments. Get in touch to find out the most suitable mortgage deal for you.";
            case "buy-to-let-remortgage":
                return "See what's the latest rates available for your BTL remortgage. Our mortgage rates are a great tool to give you an idea about the latest rates available as well as monthly mortgage payments. Get in touch to find out the most suitable mortgage deal for you.";
            case "buy-to-let-purchase":
                return "See what's the latest rates available for your BTL purchase. Our mortgage rates are a great tool to give you an idea about the latest rates available as well as monthly mortgage payments. Get in touch to find out the most suitable mortgage deal for you.";
            case "home-mover":
                return "See what's the latest rates available for your home mover mortgage. Our mortgage rates are a great tool to give you an idea about the latest rates available as well as monthly mortgage payments. Get in touch to find out the most suitable mortgage deal for you.";
            default:
        }
    }

    function renderButtons(ratesBtn, rateType) {
        // console.log(" - renderButtons - ratesBtn - " + JSON.stringify(ratesBtn));
        if (ratesBtn.length > 0) {
            // console.log(" - renderButtons in condition - ratesBtn - " + JSON.stringify(ratesBtn));
            return ratesBtn.map((item, i) => {
                // console.log(" --item.value-- " + rateType.value);
                let activeClass = '';
                if (item.value === rateType.value) {
                    activeClass = ' active';
                }
                return <div className="col-6 col-md mb-3 mb-md-0" key={i}>
                    <button className={`btn btn-primary w-100 deals-btn${activeClass}`}
                        onClick={() => changeRateActive({ value: item.value, label: item.label })}>{item.label}</button>
                </div>
            }
            )
        }
    }
}

function renderLenderRate(rateType, lendersData) {
    // console.log(" renderLenderRate " +  rateType.value)
    // console.log(lendersData)
    return <LenderRate lenderId={lendersData} type={rateType.value}
        showRates={rateType} />
}

function renderLenderDetails(props, lendersData) {
    // console.log(lendersData)
    if (lendersData.acf?.details_sections?.length > 0) {
        lendersData.acf?.details_sections.map((item, i) => {
            return <div key={i}>
                {item.content && <div className='guides-content-section container max-900 pt-4'>
                    <div className="guides-content-container" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                </div>}
                {item.cta_text && <div className='guides-content-section new-banner-container mx-auto max-700 pt-4'>
                    <div className={`container1 banner-cta mt-0 ${props.containerClasses ? props.containerClasses : ''}`}>
                        {/* <h1 className="text-center font-bold pb-3 m-0">Get in touch</h1> */}
                        <h3 className="section-header text-center py-3 m-0 text-transform-none">{item.cta_text}</h3>
                        <div className={`${!props.align ? 'row' : ''} justify-content-center mt-2`}>
                            <div className={`${!props.align ? 'col-4' : ''} text-center pb-4`}>
                                <FaPhoneAlt size={70} className='d-block mx-auto mb-2 cta-icon' color='#FF0408' />
                                <a href="tel:02080902043" className="mfs-link">Call us on 02080902043</a>
                            </div>
                            <div className={`${!props.align ? 'col-4' : ''} text-center pb-4`}>
                                <FaHeadphonesAlt size={70} className='d-block mx-auto mb-2 cta-icon' color='#43DB03' />
                                <Link to="/contact" className="mfs-link">Request a callback</Link>
                            </div>
                            <div className={`${!props.align ? 'col-4' : ''} text-center pb-4`}>
                                <FaRegEdit size={70} className='d-block mx-auto mb-2 cta-icon' color='#0091C5' />
                                <Link to="/" className="mfs-link">Submit the form</Link>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        })
    }
}

function renderAllLenderPages(lenderPages) {
    // console.log("lenderPages length => " + lenderPages.length)
    if (lenderPages.length > 0) {
        // console.log("lenderPages => " + JSON.stringify(lenderPages))
        return lenderPages.map((item, i) => {
            return <motion.div key={item.slug} className='col-md-4 mb-4 pb-2 px-lg-4 mb-md-33 text-decoration-none' style={{ 'lineHeight': '23px' }}
                variants={listVariant} >
                <Link to={item.slug} key={i} className='guides-link'>
                    <div className="guides-header" title={item.title.rendered}>{item.title.rendered}</div>
                </Link>
                <div className="guides-description">{item?.acf?.small_desc}</div>
            </motion.div>
        })
    }
}
const renderTop3Rates = (rateTypes, lenderId, lenderData) => {
    let data = rateTypes.map(item => ({
        ...item,
        lenderId: lenderId
    }));
    console.log(lenderData)
    return (
        <div className="container max-7000">
            <LenderRateView data={data} header={lenderData.small_desc} linkTo={lenderData.view_all_link} />
            {
            /*rateTypes.map((item, i) => {
                // console.log("Item name => " + item.value + " Item label " + item.label);
                return <LenderRateView lenderId={lenderId} type={item} key={i} />
            })*/}
        </div>
    )
}
export default LenderPage;